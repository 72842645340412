import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject, concat, filter, Observable, take, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SettingsModel } from './settings.model';

const API_SETTINGS_URL = `${environment.apiUrl}/settings`;

@Injectable({ providedIn: 'root' })
export class SettingsService {

  private readonly _settings$ = new BehaviorSubject<SettingsModel>({} as SettingsModel);

  public readonly settings$ = this._settings$.asObservable();

  constructor(
    private _http: HttpClient,
    private _logger: NGXLogger
  ) { }

  private _loadSettingsFromServer(): Observable<SettingsModel> {
    return this._http.get<SettingsModel>(`${API_SETTINGS_URL}`)
      .pipe(
        take(1),
        tap(settings => {
          this._logger.debug('SettingsService loadSettingsFromServer: settings loaded', settings);
          this._settings$.next(settings);
        })
      );
  }

  public getSettings(): Observable<SettingsModel> {
    const settings = concat(
      this.settings$.pipe(take(1), filter(s => !!s.raygunKey)),
      this._loadSettingsFromServer().pipe(take(1))
    );

    return settings!;
  }

}